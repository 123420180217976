import { graphql } from "gatsby";
import React from "react";
import Layout from "../../components/layout";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { Trans } from "gatsby-plugin-react-i18next";
import { DownloadIcon } from "../../images/Icons";

import TosinEniolorunda from "../../images/press/team-photos/tosin-eniolorunda.png"
import FelixIke from "../../images/press/team-photos/felix-ike.png"
import IfeanyiDuru from "../../images/press/team-photos/ifeanyi-duru.png"
import TobiAmira from "../../images/press/team-photos/tobi-amira.png"
import EmekaIbe from "../../images/press/team-photos/emeka-ibe.png"
import ChukwudumEkwueme from "../../images/press/team-photos/chukwudum-ekwueme.png"
import TomisinAraoye from "../../images/press/team-photos/tomisin-araoye.png"
import OluwakemiAdeniyi from "../../images/press/team-photos/oluwakemi-adeniyi.png"
import SolomonAmadi from "../../images/press/team-photos/solomon-amadi.png"
import EdidiongUwemakpan from "../../images/press/team-photos/edidiong-uwemakpan.png"
import BabatundeOlofin from "../../images/press/team-photos/babatunde-olofin.png"
import TosinAndFelix from "../../images/press/team-photos/tosin-and-felix.png"
import Breadcrumb from "../../components/breadcrumb";

const TeamPicturesPage = () => {
  return (
    <Layout pageTitle="Team Pictures - Moniepoint Inc.">
      <TeamPhotosContainer>
        <div className="content">
          <Breadcrumb title="Press" link="/press" current="presspage.team_pictures" />
          <div className="top-div">
            <h2 className="title">
              <Trans>presspage.team_pictures</Trans>
            </h2>
          </div>

          <div className="pictures">
            <div className="profile grow">
              <StaticImage
                alt="..."
                src="../../images/press/team-photos/tosin-eniolorunda.png"
                className="profile-image"
              />
              <div className="details">
                <div className="details-content">
                  <h4 className="profile-name">Tosin Eniolorunda</h4>
                  <h6 className="role">Founder & CEO</h6>
                </div>
                <a href={TosinEniolorunda} download="Tosin-Eniolorunda.png" className="btn download-link small">
                  <DownloadIcon size={24} />
                </a>
              </div>
            </div>
            <div className="profile grow">
              <StaticImage
                alt="..."
                src="../../images/press/team-photos/felix-ike.png"
                className="profile-image"
              />
              <div className="details">
                <div className="details-content">
                  <h4 className="profile-name">Felix Ike</h4>
                  <h6 className="role">Co-Founder & CTO</h6>
                </div>
                <a href={FelixIke} download="Felix-Ike.png" className="btn download-link small">
                  <DownloadIcon size={24} />
                </a>
              </div>
            </div>
            <div className="profile grow">
              <StaticImage
                alt="..."
                src="../../images/press/team-photos/ifeanyi-duru.png"
                className="profile-image"
              />
              <div className="details">
                <div className="details-content">
                  <h4 className="profile-name">Ifeanyi Duru</h4>
                  <h6 className="role">VP Enterpise Sales and Partnerships</h6>
                </div>
                <a href={IfeanyiDuru} download="Ifeanyi-Duru.png" className="btn download-link small">
                  <DownloadIcon size={24} />
                </a>
              </div>
            </div>
            <div className="profile grow">
              <StaticImage
                alt="..."
                src="../../images/press/team-photos/emeka-ibe.png"
                className="profile-image"
              />
              <div className="details">
                <div className="details-content">
                  <h4 className="profile-name">Emeka Ibe</h4>
                  <h6 className="role">VP Overdrafts</h6>
                </div>
                <a href={EmekaIbe} download="Emeka-Ibe.png" className="btn download-link small">
                  <DownloadIcon size={24} />
                </a>
              </div>
            </div>

            <div className="profile grow">
              <StaticImage
                alt="..."
                src="../../images/press/team-photos/chukwudum-ekwueme.png"
                className="profile-image"
              />
              <div className="details">
                <div className="details-content">
                  <h4 className="profile-name">Chukwudum Ekwueme</h4>
                  <h6 className="role">VP Enigineering</h6>
                </div>
                <a href={ChukwudumEkwueme} download="Chukwudum-Ekwueme.png" className="btn download-link small">
                  <DownloadIcon size={24} />
                </a>
              </div>
            </div>

            <div className="profile grow">
              <StaticImage
                alt="..."
                src="../../images/press/team-photos/oluwakemi-adeniyi.png"
                className="profile-image"
              />
              <div className="details">
                <div className="details-content">
                  <h4 className="profile-name">Oluwakemi Adeniyi</h4>
                  <h6 className="role">Financial Controller</h6>
                </div>
                <a href={OluwakemiAdeniyi} download="Oluwakemi-Adeniyi.png" className="btn download-link small">
                  <DownloadIcon size={24} />
                </a>
              </div>
            </div>

            <div className="profile grow">
              <StaticImage
                alt="..."
                src="../../images/press/team-photos/tomisin-araoye.png"
                className="profile-image"
              />
              <div className="details">
                <div className="details-content">
                  <h4 className="profile-name">Tomisin Araoye</h4>
                  <h6 className="role">Head, Performance Marketing</h6>
                </div>
                <a href={TomisinAraoye} download="Tomisin-Araoye.png" className="btn download-link small">
                  <DownloadIcon size={24} />
                </a>
              </div>
            </div>

            <div className="profile grow">
              <StaticImage
                alt="..."
                src="../../images/press/team-photos/tobi-amira.png"
                className="profile-image"
              />
              <div className="details">
                <div className="details-content">
                  <h4 className="profile-name">Tobi Amira</h4>
                  <h6 className="role">VP Business Loans</h6>
                </div>
                <a href={TobiAmira} download="Tobi-Amira.png" className="btn download-link small">
                  <DownloadIcon size={24} />
                </a>
              </div>
            </div>
            <div className="profile grow">
              <StaticImage
                alt="..."
                src="../../images/press/team-photos/solomon-amadi.png"
                className="profile-image"
              />
              <div className="details">
                <div className="details-content">
                  <h4 className="profile-name">Solomon Amadi</h4>
                  <h6 className="role">VP Processing Infrastructure</h6>
                </div>
                <a href={SolomonAmadi} download="Solomon-Amadi.png" className="btn download-link small">
                  <DownloadIcon size={24} />
                </a>
              </div>
            </div>
            <div className="profile grow">
              <StaticImage
                alt="..."
                src="../../images/press/team-photos/edidiong-uwemakpan.png"
                className="profile-image"
              />
              <div className="details">
                <div className="details-content">
                  <h4 className="profile-name">Edidiong Uwemakpan</h4>
                  <h6 className="role">Head, Marketing</h6>
                </div>
                <a href={EdidiongUwemakpan} download="edidiong-uwemakpan.png" className="btn download-link small">
                  <DownloadIcon size={24} />
                </a>
              </div>
            </div>
            <div className="profile grow">
              <StaticImage
                alt="..."
                src="../../images/press/team-photos/babatunde-olofin.png"
                className="profile-image"
              />
              <div className="details">
                <div className="details-content">
                  <h4 className="profile-name">Babatunde Olofin</h4>
                  <h6 className="role">EVP Cards & Payroll</h6>
                </div>
                <a href={BabatundeOlofin} download="babatunde-olofin.png" className="btn download-link small">
                  <DownloadIcon size={24} />
                </a>
              </div>
            </div>
            <div className="profile grow">
              <StaticImage
                alt="..."
                src="../../images/press/team-photos/tosin-and-felix.png"
                className="profile-image"
              />
              <div className="details">
                <div className="details-content">
                  <h4 className="profile-name">Tosin and Felix</h4>
                </div>
                <a href={TosinAndFelix} download="Tosin-and-Felix.png" className="btn download-link small">
                  <DownloadIcon size={24} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </TeamPhotosContainer>
    </Layout>
  );
};

export default TeamPicturesPage;

export const query = graphql`
   query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const TeamPhotosContainer = styled.section`
  background-color: white;

  .content {
    max-width: 1440px;
    margin: auto;
    padding: 0 150px 150px;
    @media only screen and (max-width: 1024px) {
      padding: 0 60px 150px;
    }

    .top-div {
      margin-bottom: 16px;

      .title {
        font-family: "Founders Grotesk";
        font-size: 48px;
        font-weight: 500;
        line-height: 57px;
        max-width: 851px;
      }
    }
    .bottom-div {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      .text-content {
        font-size: 20px;
        font-weight: 400;
        line-height: 32.2px;
        letter-spacing: 0em;
        text-align: left;
        max-width: 586px;
        margin-bottom: 0;
      }
    }
    .pictures {
      margin-top: 48px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-auto-rows: 1fr;
      grid-gap: 49px;
      padding-bottom: 20px;

      @media only screen and (max-width: 1024px) {
        grid-template-columns: repeat(3, 1fr);
      }

      .profile {
        max-width: 261px;
        .profile-image {
          width: 100%;
          height: auto;
          border-radius: 8px;
          margin-bottom: 11px;
        }
        .profile-name {
          font-size: 16px;
          font-weight: 600;
          line-height: 26px;
          letter-spacing: -0.005em;
          text-align: left;
          margin-bottom: 0;
        }
        .role {
          font-size: 13px;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: -0.005em;
          text-align: left;
          margin-bottom: 0;
        }
        .details{
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          gap: 12px;
        }

      }
    }
  }

  @media screen and (max-width: 768px) {
    .content {
      padding: 16px 20px 64px;

      .top-div {
        margin-bottom: 16px;
        .title {
          font-size: 35px;
          line-height: 41px;
        }
      }
      .bottom-div {
        flex-direction: column;
        align-items: flex-start;
        .text-content {
          font-size: 14px;
          line-height: 21px;
          margin-bottom: 16px;
        }
        .button {
          padding: 5.5px 16px;
        }
      }
      .pictures {
        margin-top: 24px;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 16px;

        .profile{
          .profile-name {
          font-size: 14px;
        }
        .role {
          font-size: 12px;
        }
        }
      }
    }
  }
`;
